import React from 'react';
import './App.css';
import AppRoutes from './routes';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/night-mode.css';
// Vendor CSS Files
// import './assets/vendor/unicons-2.0.1/css/unicons.css'
import './assets/vendor/fontawesome-free/css/all.min.css';
import './assets/vendor/OwlCarousel/assets/owl.carousel.css';
import './assets/vendor/OwlCarousel/assets/owl.theme.default.min.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css';

import './assets/css/eventox.css';
import { Provider } from 'react-redux';
import store from './store/store';

//Style 

function App() {
  return (
    <Provider store={store}>
      <AppRoutes />
      </Provider>
  

  );
}

export default App;
